<template>
  <div class="mt-2" v-if="type==1">
    <subscribeSubTable
      :result="result"
      addingURL="serve.php?f=configuration&f2=ldapContent"
      calledFunction="ldapContentAdd"
      tableTitle="LDAP Content"
      :subTable="result.additional.callLdapTable"
    ></subscribeSubTable>
  </div>
</template>
<script>
import subscribeSubTable from "@/components/legacy/subscribeSubTable.vue";
export default {
  props: ["result"],
  components: { subscribeSubTable },
  computed: {
    type() {
      return this.result.valueLine.n_type;
    },
  },
};
</script>